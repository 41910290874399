.highlights-container {
  background: #0E3560;
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem;
}

.highlights-title {
  display: flex;
  align-items: center;
  justify-content: space-around;

  & h1 {
    color: white;
    font-size: 250%;
  }

  & img {
    width: 200px;
  }
}

.carousel {
  margin-top: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.carousel-highlight {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 50vh;
}

.carousel-buttons {
  display: flex;
  margin-top: 1rem;
}

@media (max-width: 1080px) {
  .highlights-container {
    display: flex;
    padding: 0 0 1rem 0;
  }

  .highlights-title {
    display: flex;
    flex-direction: column;
    text-align: center;

    & h1 {
      font-size: 200%;
    }

    & img {
      width: 150px;
    }
  }

  .carousel-highlight {
    overflow: hidden;
    position: relative;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}