.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 4rem 2rem 4rem;

  & img {
    width: 150px
  }
}

.header-buttons-container {
  & button {
    position: relative;
    margin-left: 2rem;
    color: #333333;
    font-size: 120%;
    text-decoration: none;
    user-select: none;
    background: transparent;
    border: none;
    outline: inherit;
    padding: 0;
    font-family: inherit;
    font-weight: 700;
  }

  & button:hover {
    color: #268BA9;
    cursor: pointer;
    user-select: none;
  }

  & button:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #268BA9;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  & button:hover::before {
    transform: scaleX(1);
  }
}

.header-menu-container {
  display: none;
}

@media (max-width: 1080px) {
  .header-container {
    padding: 2rem 2.5rem 1rem;
    position: relative;

    & img {
      width: 100px;
    }
  }

  .header-logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-content: center;
  }

  .header-buttons-container {
    display: none;
  }

  .header-menu-container {
    display: flex;
    cursor: pointer;
  }

  .header-drawer {
    font-family: 'Lato', sans-serif !important;
    font-weight: 700 !important;
  }
}