.contact-us-container {
  background: #0E3560;
}

.contact-us-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
  text-align: center;
  flex-direction: column;

  & h1 {
    color: white;
    font-size: 250%;
    margin: auto;
  }

  & h2 {
    color: white;
    font-size: 200%;
    margin: 2rem auto;
  }

  & p {
    color: white;
    font-size: 150%;
    margin: auto;
  }

  & button {
    margin: 5rem;
  }
}

.recording-button {
  background-color: white;
  height: 100px;
  margin-top: 3rem;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 0 4rem;
  cursor: pointer;

  .recording-icon {
    margin-right: 1rem;
    font-size: 70px;
  }

  & p {
    color: #268BA9;
    font-size: 300%;
    margin: unset;
    user-select: none;
  }
}

@media (max-width: 1080px) {
  .contact-us-content {
    padding: 2rem 1rem 3rem;

    & h1 {
      font-size: 200%;
    }

    & h2 {
      font-size: 150%;
      margin: 1rem 4rem;
    }

    & p {
      font-size: 100%;
      margin: 0 4rem;
    }

    & button {
      margin: 0;
    }
  }

  .recording-button {
    height: 75px;
    padding: 0 2rem;

    .recording-icon {
      font-size: 50px;
    }

    & p {
      margin: 0;
      font-size: 150%;
    }
  }
}