.toggle {
  background: transparent;
  border: none;
  cursor: pointer;
}

.icon {
  transition: transform 0.3s ease;
}

.icon.off {
  transform: scale(1);
}

.icon.on {
  transform: rotate(360deg) scale(0);
}