.services-container {
  .mesh {
    position: absolute;
    z-index: -1;
    width: 100%
  }
  background: url('../../assets/mesh.png') no-repeat top center;
  background-size: contain;
  padding: 0 3rem;
}

.services-content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .football-man {
    width: 100vh;
    margin: 0 -200px;
    object-fit: contain;
  }

  & h1 {
    color: #268BA9;
    font-size: 250%;
  }

  & h2 {
    color: #333333;
    font-size: 200%;
  }

  & p {
    color: #333333;
    font-size: 150%;
  }
}

@media (max-width: 1080px) {
  .services-container {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }
  .services-content {
    display: flex;
    flex-direction: column;
    text-align: center;

    .football-man {
      width: 100%;
    }

    .services-info {
      padding: 0 3rem;
    }

    & h1 {
      font-size: 200%;
    }

    & h2 {
      font-size: 150%;
    }

    & p {
      font-size: 100%;
    }
  }
}