.how-do-we-work-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0 4rem;

  & h1 {
    color: #268BA9;
    font-size: 250%;
  }
}

.cards {
  display: flex;
}

.cards-swiper {
  display: none;
}

@media (max-width: 1080px) {
  .how-do-we-work-container {
    display: flex;
    padding-bottom: 2rem;

    & h1 {
      font-size: 200%;
    }
  }

  .cards {
    display: none;
  }

  .cards-swiper {
    display: flex;
    width: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: stretch;
    }
  }
}