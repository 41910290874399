.card {
  border-radius: 20px;
  border: 5px solid #eeeeee;
  overflow: hidden;
  width: 25vw;
  margin-left: 1rem;
  margin-right: 1rem;
}

.card-icon {
  padding: 2rem;
}

.card-content {
  padding: 2rem;
  background-color: #eeeeee;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 5px solid #eeeeee;
  overflow: hidden;
  height: 100%;

  & h2 {
    color: #333333;
    font-size: 250%;
  }

  & p {
    color: #333333;
    font-size: 150%;
  }
}

@media (max-width: 1080px) {
  .card {
    width: 70vw;
  }

  .card-icon {
    padding: 1rem;
  }

  .card-content {
    padding: 1rem;

    & h2 {
      font-size: 150%;
    }

    & p {
      font-size: 100%;
    }
  }
}