.our-team-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 1rem;
  flex-direction: column;

  & h1 {
    color: #268BA9;
    font-size: 250%;
  }
}

.our-team-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 15rem 5rem 5rem;

  .avatar {
    width: 300px;
    height: 300px;
    position: relative;
  }

  & img {
    position: absolute;
    object-fit: cover;
    object-position: top center;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    filter: grayscale(1) contrast(1) brightness(1);
    transition: opacity 0.5s ease;
  }

  & img.hidden {
    opacity: 0;
  }

  .our-team-info {
    text-align: left;
    margin-left: 20rem;
  }

  & h2 {
    color: #333333;
    font-size: 250%;
  }

  & p {
    color: #333333;
    font-size: 150%;
  }
}

@media (max-width: 1080px) {
  .our-team-container {
    display: flex;
    padding: 0;

    & h1 {
      font-size: 200%;
    }
  }

  .our-team-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0;

    .avatar {
      width: 250px;
      height: 250px;
      position: relative;
    }

    & img {
      display: flex;
      width: 250px;
      height: 250px;
    }

    .our-team-info {
      margin: 0;
      padding: 0 5rem;
      text-align: center;
    }

    & h2 {
      font-size: 150%;
    }

    & p {
      color: #333333;
      font-size: 100%;
    }
  }
}